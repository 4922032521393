.cta-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #000;
  border-radius: 64px;
  border-style: none;
  cursor: pointer;
  border: none;
  outline: 0;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue-bg {
  width: 150px;
  padding: 12px 16px;
  background: #71CEFF;
}

.linear-bg {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 28px */
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: linear-gradient(271deg, #ECFF75 6.76%, rgba(255, 60, 235, 0.70) 98.96%), #FFF;
}

.round {
  display: inline-flex;
  align-items: flex-start;
  gap: 20px;
  border-radius: 43px;
  color: var(--000000, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.cta-button:focus {
  outline: none;
  box-shadow: none;
}

.glow-on-hover {
  width: 250px;
  height: 50px;
  outline: none;
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    45deg,
    #00ffff,
    #02e1fe,
    #0076fd,
    #004cff,
    #00ffd5,
    #00f2ff,
    #0059ff,
    #00d5ff,
    #0062ff
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  border-radius: 10px;
}

.glow-on-hover:active:after {
  background: transparent;
  border-radius: 10px;
}

.glow-on-hover:hover:before {
  opacity: 1;
  border-radius: 10px;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
